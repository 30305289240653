.preiframe{
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 45px);
    position: relative;
    background-color: #000000;
}
.bottms{
    background-color: #034F4B;
    position:fixed;
    bottom:0;
}
.pimgs{
    padding-top: 10px;
    padding-bottom: 10px;
}
.pext{
    color: red;
    cursor: pointer;
}

.videoicon{
    color: #ffffff;
}

.controlsbarbutton{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.controlsbartooltip {
    min-width:50px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.controlsbarbutton:hover .controlsbartooltip {
    visibility: visible;
    opacity: 1;
}
.floating-panel {
    position: absolute;
    border: 1px solid #ccc;
    background-color: white;
    cursor: move;
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #007bff;
    cursor: nwse-resize;
    bottom: 0;
    right: 0;
}

.content {
    padding: 10px;
}
.bottomvideo{
    position: fixed;
    z-index: 9999;
    width:320px;
    bottom: 44px;
    background-color: #000000;
}
.ondemaniframe{
    width:320px;
    height:190px;
}
