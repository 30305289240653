.slidepage{
    width:90%;
    margin:20px auto;
    font-family: sans-serif;
}
.textlistpage{
    min-height: 100vh;
   overflow: auto;
}
.slidecaption{
    font-size: 36px;
    width:80%;
    margin-left:10%;
    margin-top:10px;
    background: transparent;
    text-align: center;
}
ul.leftpoint{
    list-style-type:circle;
    padding: 20px;
}
.react-colorful{
   width:100px !important;
    height: 100px !important;
}
.react-colorful__pointer{
    width:14px !important;
    height: 14px !important;
}
.slidemessage{
    width:100%;
    font-size: 25px;
    background: transparent;
}
.listbody{
    width:90%;
    margin-left: 6%;
}

.textareas{
    width:100%;
}

.textslidesshowpage{
    width:100%;
    height: 100vh;
    padding-top:70px;
    margin: 0 auto;
}
.textslidesshowpageheader{
    width:80%;
    font-size: 36px;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
}
.vircalcenter{
    margin: 0;
}
.alltextlists{
    margin-left: 30px;
    margin-right: 30px;
}
.textlists{
    font-size: 25px;
}
.editslidecaption{
    font-size: 25px;
    width:80%;
    margin-left:7%;
    margin-top:10px;
    background: transparent;
    text-align: center;
}
.editslidemessage{
    width:90%;
    font-size: 18px;
    background: transparent;
}
#textpageback{
    padding:10px 15px
}
.thiscolorchange{
    width:100px;
    border-top: none;
    border-left: none;
    border-right: none;
}
.left20{
    margin-left: 30px;
}
.newtextarea{
    width:100%;
}
.newtextslidepage{
    width:98%;
    margin:30px auto;
    border:2px solid #0c4128;
    border-radius: 10px;
}
.rdw-embedded-modal, .rdw-link-modal {
    width:250px !important;
    height:230px !important;
}
.newtextshowpage{
    width:80% !important;
    margin:10px auto;
    padding:20px;
}
.newtextslidepagebottom{
    width:95%;
    margin-bottom:80px;
    padding:10px;
    text-align:right;
}
.rdw-colorpicker-modal{
    width:250px !important;
    height:230px !important;
}
.rdw-editor-toolbar{
    justify-content: center !important
}
.rdw-dropdown-optionwrapper{
    max-height:290px !important
}
.rdw-option-wrapper img{
    width:25px !important
}
.demo-popup-custom{
    padding:15px !important
}
.rdw-link-modal-input{
    height:30px !important
}
.rdw-emoji-modal{
    width:280px !important;
    height:250px !important;
}
.DraftEditor-editorContainer{
    padding-left: 15px !important;
    padding-right: 15px !important;
}
@media screen and (max-width: 800px) {
    .newtextshowpage{
        width:90% !important;
        padding:15px;
    }
}
@media screen and (max-width: 600px) {
    .newtextshowpage{
        width:98% !important;
        padding:10px;
    }
}
