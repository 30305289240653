.pdfpage{
    margin:30px auto;
    text-align: center;
    width:100%;
    height:100%
}
.pdfiframe{
    width:25%;
    height: 100% ;
    border:2px solid #000000;
    display: inline-block;
    padding:5px;
}
.pdfiframes{
    width:100%;
    min-height:550px;
    scroll-margin: 0;
}
.publiclessonpreview{
    width:25%;
    height:550px;
    position: absolute;
}
.publiclessonpreview1{
    width:100%;
    height:650px;
    position: absolute;
}
@media print {
    .pdfiframes {
        display: block; /* Ensure iframes are visible when printing */
        width: 100%; /* Adjust width as needed */
        height: 100%; /* Adjust height as needed */
        /* Additional styles for iframe content when printing */
    }
}
