.videoquessetupplage{
    width:960px;
    margin:10px auto
}
.py-3{
    text-align: center;
}
.mr-3{
    background-color: #0c4128;
}
.centerthis{
    text-align: center;
}
@media screen and (max-width: 960px) {
    .videoquessetupplage{
        width:100%;
        margin:5px auto
    }
}
