.amplify-button {
    background-color:#595959;
    border: 1px solid #595959;
    color:#ffffff
}

.amplify-button:hover {
    background: #a0dcdc;
}
.amplify-tabs{
    display: none;
    border:none
}
[data-amplify-authenticator] [data-amplify-router]{
    border: none;
    box-shadow: none;
}
.amplify-tabs[data-indicator-position=top]{
    border:none
}
.signuppage{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.signupright{
    width:50%;
    height:100vh;
    background-color: #034F4B;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
}
.signupright1{
    width:50%;
    height:100vh;
    background-color: #000000;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
}
.signupleft{
    width:50%;
}
.signupright img{
    width:650px;
    display: flex;
    align-items: center;
}
.signupright1 img{
    width:565px;
    display: flex;
    align-items: center;
}
.smallsize{
    font-size: 14px;
}
.underlinetext{
    text-decoration-line: underline;
}
.smallsizefooter{
    font-size: 14px !important
}
.smallsizefooter1{
    font-size: 14px !important;
    color: #034F4B;
    text-decoration: underline;
}
.signuplogo{
    width:416px;
}
@media only screen and (max-width: 1300px) {
    .signupright img{
        width:100%
    }
}
@media only screen and (max-width: 1200px) {
    .signupright1 img{
        width:100%
    }
}
@media only screen and (max-width: 1000px) {
    .amplify-flex .amplify-textfield{
        width:380px !important
    }
    .signuplogo{
        width:380px;
    }
    .amplify-button[data-variation='primary']{
        width:380px
    }
}
@media only screen and (max-width: 900px) {
    .signupleft{
        width:100%;
    }
    .signupright{
        width:0
    }
    .signupright1{
        width:0
    }
    .amplify-flex .amplify-textfield{
        width:416px !important
    }
    .signuplogo{
        width:416px;
    }
    .amplify-button[data-variation='primary']{
        width:416px
    }
}
