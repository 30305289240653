.doughhutsize{
    width:96%;
    margin:0 auto
}
.engageheight{
    margin-top:40%;
    text-align: left !important;
}
.engageheightright{
    margin-top:30%;
    text-align: right !important;
}
.hoverreportmenu:hover{
    color:#26967b;
}
