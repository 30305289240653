.draw {
    margin: 2em auto;
    width: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.drawpicker-container {
    position: relative;
    display: inline-block;
}

.drawpicker-container button {
    width: 100%;
}

.drawpicker-container .drawpickerpopper {
    position: absolute;
    z-index: 2;
    padding-top: 4px;
}

.drawpickerpopper div.github-picker{
    border-radius: 0 !important;
    border: 1px solid #ddd !important;
    padding: 8px !important;
    background: #fffffe !important;
    box-shadow: 8px 8px #995 !important;
}


.drawcanvas:not(.disabled) {
    border: 1px solid #ddd;
    cursor: none;
    box-shadow: 4px 4px #995;
    margin: 6px 4px 6px 0;
}

.drawbutton-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.drawbutton-container > * {
    width: 33.33%;
}

.drawbutton-container button {
    -webkit-appearance: none;
    background: #daf4a2;
    padding: 0.5em 1em;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
}

.palette:hover {
    background: #034F4B;
    color: white;
}

.undo:hover {
    background: #fccb00;
}

.clear:hover {
    background: #fd7e14;
    color: white;
}

.clear .hover {
    display: none;
}

.clear:hover .non-hover {
    display: none;
}
.clear:hover .hover {
    display: inline;
}
.thisborder{
    border:1px solid #000000
}
.fullwidth{
    width:650px;
}
.blackboardshows{
    margin:0 auto;
    width:680px
}
@media screen and (max-width: 650px) {
    .fullwidth{
        width:100%;
    }
}
@media screen and (max-width: 680px) {
    .blackboardshows{
        width:100%
    }
}

