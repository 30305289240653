.uploadurl{
    max-width: 650px;
    margin:20px auto;
    background-color: #ffffff;
    border-radius: 8px;
    padding:30px 25px 100px
}
.liveurl{
    max-width: 650px;
    margin: 10px auto;
}
.urlsform{
    width:380px;
    border:solid #72B5A4 5px;
}
.form-control{
    border:solid #72B5A4 5px;
}
.ext{
    margin-right:50px;
}

.inputstyles {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid #DAF4A2;
    width:120px;
    height:44px;
    background-color: #DAF4A2;
    color: #034F4B;
    border-radius: 5px;
    margin-top:-2px;
    font-weight: 500;
}
#emessage{
    color: #E82C82;
    text-align: center;
    margin-top:90px;
}
.contentform{
    width: 650px;
    margin:20px auto
}
.urlsvg{
    width:650px
}
@media only screen and (max-width: 650px) {
    .urlsvg{
        width:100%
    }
}
