.footer{
    text-align: center;
    font-size: 85%;
    position: relative;
    bottom: 0;
    margin-top: 100px;
    padding-bottom: 80px;
}
.ptext{
    text-decoration: underline;
}
p{
    cursor: pointer;
}
.footclose{
    color: #e24761;
}
.botts{
    width:100%;
    text-align: center;
    position:fixed;
    bottom:0;
    height:65px;
    font-size:85%;
    background-color: #bfbebe;
}
.botts p{
    padding-top: 12px;
}
@media only screen and (max-width: 540px) {
    .botts{
        height:120px;
    }
    .footer{
        padding-bottom: 130px;
    }
}
