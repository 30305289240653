.supportpage{
    width:96%;
    margin:30px auto
}
.supportpageleft{
    width:96%;
    margin: 0 auto;
}
.thisfasttext{
    text-decoration: underline;
}
