.fullvideoqesshowpage{
    width:100%;
    height:100vh;
    background-color: #034f4b;
}
.videoqesshowpage{
    width:960px !important;
    padding-top:10px;
    margin:0 auto !important;
}
.reactplayer {
    width: 100% !important;
    height: auto !important;
}
.showoptionpage{
    margin:0 auto
}
.videoplayplay{
    width:300px;
    height:300px;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 9999;
}
.vfastanswers{
    padding-top: 20px;
    padding-bottom: 50px;
}

@media screen and (max-width: 960px) {
    .videoqesshowpage{
        width:100% !important;
    }
}
@media screen and (max-width: 700px) {
    .videoplayplay{
        width:200px;
        height:200px;
    }
    .playercenter{
        width:200px;
        height:200px;
    }
}
