.fastaneditpage{
    width:90%;
    margin:20px auto;
    height:500px;
}

.singleradio{
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 3;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    width: 30px;
    height: 30px;
    position: absolute;
    margin-left:-40px;
}
.multicheckbox{
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 3;
    display: grid;
    width: 30px;
    height: 30px;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    position: absolute;
    margin-left:-40px;
}
.fillinthimgshow{
    border: 2px solid #034F4B;
    width:400px;
}
.mathinputstyle{
    width:500px;
    margin:10px auto
}
@media screen and (max-width: 500px) {
    .mathinputstyle{
        width:100%
    }
}
@media screen and (max-width: 400px) {
    .fillinthimgshow{
        width:100%;
    }
}
