.card {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
}
.card img {
    width: 100%;
    height: 100%;
}
.card .card-face {
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
}
.card .card-face.card-back-face {
    transform: rotateY(180deg);
}
.card.is-flipped {
    transform: rotateY(180deg) scale(1.1,1.1);
    z-index:9999;
    border:1px solid #0c4128;
}
.card.is-inactive {
    opacity: 0;
}
.flipcenter{
    text-align: center;
    height:300px;
}
.flipcardp{
    padding-top:18px
}

.greenbackcenter{
    text-align: center;
    background-color: #269B7B;
    padding:10px
}
.lpollheadclockflip{
    width:50px;
    margin: 0 auto;
}
