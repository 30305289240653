.prices{
    width:80%;
    margin:10px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 7px;
}
.redthisall{
    color: #e82c82;
    font-family: "brother-1816", Sans-serif;
}
.topheader{
    color: white;
    background-color: #72b5a4;
    padding: 5px;
}
.cardback{
    margin-top: -17px;
    background-color: #034f4b;
    color: #ffffff;
    padding-top: 20px;
    padding-bottom: 10px;
}
.priceshead{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}
.pricesfirstcard{
    padding-top:43px;
    padding-bottom: 43px;
}
.column {
    float: left;
    width: 50%;
    padding: 0 10px;
}
.pricebutton{
    background-color: #034f4b;
    color: #ffffff;
    border: solid #72b5a4 1px;
}
.pricecenter{
    margin-top: 20px;
    text-align: center;
}
/* Remove extra left and right margins, due to padding */
.row {margin: 30px;}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}
.underline{
    text-decoration: underline;
    color: #034F4B;
}
/* Responsive columns */
@media screen and (max-width: 800px) {
    .prices{
        width:100%;
    }
}
@media screen and (max-width: 550px) {
    .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }
}
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    width:100%;
    text-align: center;
    background-color: #ffffff;
    color:#034F4B;
}
.checkoutcenter{
    text-align: center;
    margin-top: 50px;
}

.thankyoupage{
    text-align: center;
    width:500px;
    margin: 20px auto;
}

@media screen and (max-width: 500px) {
    .thankyoupage{
        width:100%;
    }
    .thanks{
        width:100%
    }
}

.pricingbutton{
    background-color: #034F4B;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
}
.icode{
    font-size: 13px;
    color: #db6707;
}
