.flipcardpage{
    width:100%;
    text-align: center;
    font-size: 16px;
    margin-bottom: 80px;
}
.game-board {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    perspective: 1000px;
    margin-bottom: 0;
}
.game-board .card {
    width: 180px;
    user-select: none;
    height: 100px;
    padding: 7px;
    box-sizing: border-box;
    text-align: center;
    margin: 10px;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}
.game-board .card div {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 1s;
    background: #ecebeb;
}
.game-board .card .back {
    font-size: 20px;
    line-height: 108px;
    cursor: pointer;
    color: #0c4128;
    display: flex;
    align-items: center;
    justify-content: center;
}
.game-board .card .front {
    transform: rotateY(180deg);
    line-height: 110px;
    text-emphasis: none;
}
.game-board .card .front img {
    vertical-align: middle;
    width: 70%;
    max-width: 118px;
    max-height: 75%;
}
.game-board .card.flipped {
    transform: rotateY(180deg);
}
.game-board .card.matched {
    transform: rotateY(180deg);
}
.game-board .card.matched .front {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
    animation: selected 10s 0s ease 1;
    animation-fill-mode: both;
    opacity: 0.2;
}
.centered {
    width: 100%;
    height: 100%;
    text-align: center;
}
@keyframes selected {
    0% {
        opacity: 0.2;
    }
    30% {
        opacity: 0.5;
    }
    50% {
        opacity: 0.9;
    }
    70% {
        opacity: 0.2;
    }
    100% {
        opacity: 0.3;
    }
}
.flipcardpageheader{
    padding-top: 60px;
    width:320px;
    margin:0 auto
}
.startbutton{
    padding-top:60px;
}

.flipcardwin{
    padding-top: 60px;
    width:220px;
    margin:0 auto;
}
.finput{
    width:100%;
    height:35px
}
.eq{
    text-align: center;
}
@media screen and (max-width: 800px) {
    .flipcardpage{
        width:100%;
        margin:10px auto;
    }
}

@media screen and (max-width: 650px) {
    .game-board .card {
        width:30%;
        padding: 0;
        margin: 3px;
    }
    .flipcardwin{
        padding-top: 20px;
    }
    .startbutton{
        padding-top:20px;
    }
    .flipcardpageheader{
        padding-top: 20px;
    }
}
.flashheadclock{
    width:50px;
    margin:0 auto
}
.lflashpollheadclock{
    padding:0
}
