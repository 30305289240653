.editroom{
    width:100%;
    margin:20px auto;
    padding: 10px;
    text-align: center;
}
.notes{
    text-align: left;
    margin-bottom: -15px;
    color:#E82C82;
}
.greens{
    color:#034F4B
}
.movethis{
    cursor: all-scroll;
}
.firstadds{
    margin-left: -30px;
}
.lialist{
    width:235px;
    height:200px;
    margin:5px 3px 2px;
    background-color: #595959;
    display: inline-block;
    border-radius: 4px;
}
.editinput{
    -webkit-appearance: none;
    border-width:0px;
    border:none;
}
.noi{
    position: absolute;
}
.editnoi{
    position: absolute;
    width: 229px;
    height: 130px
}
.editsframelook{
    width:229px;
    height:130px;
    overflow-y: hidden;
    overflow-x: hidden;
}
.editsframe{
    width:125px;
    height:72px;
    overflow: hidden !important;
    border-radius: 10px;
}
.edithead{
    text-align: center;
    margin-bottom: 10px;
}
.adds{
    text-align: center;
}
.bttons{
    margin-left: 20px;
}
.saves{
    width:96%;
    margin: 0 auto;
}

.golivebutton{
    width:130px;
    background-color: #269b7b;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #269b7b;
}

.savesheader{
    width:94.5%;
    margin: -5px auto;
}
.topspace{
    margin-bottom: 10px;
}
.sharedropbtn{
    padding:4px 10px;
    color: #000000;
    cursor: pointer;
}
.addsd{
    color: #174963;
    cursor: pointer;
}
.col-md-auto{
    margin-bottom: 10px;
}
.eext{
    width:150px;
    margin-left:30px
}
.editroomlist{
    width:95%;
    height: 25px;
    font-size: 15px;
    text-align: center;
}

.characters {
    margin: 0;
    padding-bottom: 30px;
    color: #ffffff;
}
.editspan{
    color: white;
    font-size: 13px;
}
.editcontents{
    font-size: 22px;
    font-weight: 500;
    color: #000000;
}
.topedit{
    padding:5px
}
.imgslist{
    margin-top:0
}
.ccopys{
    padding:10px;
}
.cccenter{
    text-align: center;
}
.ccc{
    margin-left: 6px;
    background-color: #DAF4A2;
    color: #034F4B;
    border: 1px solid #DAF4A2;
}
.inputsave {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid #DAF4A2;
    width:80px;
    height: 31px;
    background-color: #DAF4A2;
    color: #034F4B;
    border-radius: 5px;
    font-size: 18px;
}
.contents{
    color: #034F4B;
}
#mySelect{
    width: 160px;
    border: 1px solid #DAF4A2;
    color: #034F4B;
    background-color: #DAF4A2;
    font-size: 15px;
    margin-right: 3px;
    border-radius: 5px;
    font-weight: 500;
}
.dragable{
    width:10px;
}

.addse{
    text-align: right;
}
.upgradenew1{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}
.ibuttonss{
    width:90px;
    background-color: #DAF4A2;
    color:#034F4B
}
.tooltiptext1 {
    min-width:150px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew1:hover .tooltiptext1 {
    visibility: visible;
    opacity: 1;
}
.upgradenew4{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltiptext4 {
    min-width:120px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew4:hover .tooltiptext4 {
    visibility: visible;
    opacity: 1;
}
.upgradenew5{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltiptext5 {
    min-width:250px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew5:hover .tooltiptext5 {
    visibility: visible;
    opacity: 1;
}
.rehearsebutton {
    width:130px;
    background-color: #01312e;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #01312e;
}

.inputsavechanges{
    width:400px;
    background-color: #E82C82;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #E82C82;
}
.addqrbtn{
    width:130px;
    background-color: #03706b;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #03706b;
}
.zooms{
    width:32px;
    height:32px;
    margin:0 auto
}
.input-container {
    position: relative;
}
.inputicon{
    position: absolute;
    top: 50%;
    right: 21%;
    transform: translateY(-50%);
}
.editroomnamebig{
    font-size: 27px;
    font-weight: 500;
    color: #000000;
    border: none;
    text-align: center;
    width:60%;
    padding-right: 30px;
    border-bottom: 1px solid #daf4a2;
}
@media only screen and (max-width: 850px) {
    .editroom{
        max-width:100%;
    }
}
.saveseditchage{
    width:99%
}


.edititemsselected {
    background-color: #fab73d;
    transform: rotate(3deg);
    color:#ffffff
}
.menuslide{
    text-align: left !important;
    padding:5px
}
.menuspace{
    padding: 5px;
}
.smallhr{
    margin-top:-8px
}
