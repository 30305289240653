.dropbtn {
   border-radius: 5px;
}

.dropbtn:hover, .dropbtn:focus {
    background-color: #ffffff;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    background-color: #ffffff;
    right:0;
    width: 600px;
    height:525px;
    overflow: auto;
    border: 2px solid #9f9f9f;
    z-index: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align:left;
    padding-left: 10px;
}
.addcontentbtn{
    background-color:#034F4B;
    border: 1px solid #034F4B;
    padding:4px 11px;
    color: #ffffff;
    width:130px
}
.adddropbtn{
    background-color:#DAF4A2;
    border-radius: 5px;
    border: 1px solid #daf4a2;
    padding:4px 15px;
    color: #034F4B;
}
.dropdown-content p{
    color: #034F4B;
    display: block;
    padding: 2px 8px;
}
.dropdown2 {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    color:#034F4B
}
.dropdown3 {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    color:#034F4B
}
.dropdown2-content {
    position: absolute;
    width: 250px;
    height:300px;
    overflow: auto;
    z-index: 1;
    padding-top: 5px;
    border: 1px solid #72B5A4;
    border-radius: 5px;
    text-align: left;
    padding-left: -200px;
    background-color: white;
}
.dropdown3-content {
    padding-top: 10px;
    width:450px;
    border: 1px solid #72B5A4;
    border-radius: 5px;
    text-align: left;
    padding-left: -200px;
}
.leadermenuhead{
    padding-top: 15px;
}
.leftmenu{
    padding-top: 7px;
    padding-left: 20px;
}
.dropdown3-content p{
    color:#034F4B
}
.littletop{
    margin-top:5px
}
.dropdown2-content span{
    color:#034F4B;
    display: block;
    margin-left:15px
}
.dropdown-content p:hover {background-color: #034F4B;color:#ffffff}
.dropdown2-content span:hover {
    background-color: #034F4B;
    color: #ffffff;
    padding-left: 20px;
}
.dropdown:hover .dropdown-content {display: block;}
.dropdown2:hover .dropdown2-content {display: block;}

.show {display: block;}
.tooltipgame {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltipgame .tooltiptextgame {
    visibility: hidden;
    width: 230px;
    background-color: #e3e3e3;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    margin-left: 0;
}

.tooltipgame:hover .tooltiptextgame {
    visibility: visible;
}
.contentnew5{
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.contentnew5 .contenttooltiptext5 {
    visibility: hidden;
    width: 280px;
    background-color: #e3e3e3;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    margin-left: 0;
}

.contentnew5:hover .contenttooltiptext5 {
    visibility: visible;
}
.thisspan{
    padding: 5px;
    cursor: pointer;
}
.disfull{
    width:640px;
    border:5px solid #000000
}
.centerthisgiphy{
    text-align: center;
    width:640px;
    margin:0 auto;
    border: 6px solid #000000;
}
.disfullcenter{
    width:640px;
    margin:0 auto;
    text-align: center;
}
.disfull1{
    width:100%;
    border: 6px solid #000000;
}
@media only screen and (max-width: 991px) {
    .disfullcenter{
        width:100%
    }
    .disfull{
        width:100%
    }
    .centerthisgiphy{
        width:100%;
    }
}
