.lmviframe{
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #000000;
    overflow: hidden;
}
.mviframe{
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 45px);
    position: relative;
    background-color: #000000;
    overflow: hidden;
}
.bottoms{
    background-color: #000000;
    position:fixed;
    bottom:0;
    padding-left:10px
}
.mmbottoms{
    background-color: #000000;
    position:fixed;
    bottom:0;
    padding-left:10px
}
#leftsid{
   background-color: #034F4B;
}
form{
    padding:10px;
}
.mform{
    width:230px;
    height:30px
}
.submits{
    background-color: #174963;
    border-radius: 5px;
    height:30px
}
.mimg{
    padding-top: 10px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;
}
.butto{
    color: #E24761;
    cursor: pointer;
}
.hostright{
    text-align: center;
}
.mvideoicon{
    color: #ffffff;
}
.endmeetingb{
    padding-top: 10px;
    padding-bottom: 9px;
}
.hosttooltips{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.hostcontrolsbartooltip{
    width:80px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.hosttooltips:hover .hostcontrolsbartooltip {
    visibility: visible;
    opacity: 1;
}
.upgradenew10{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltiptext10 {
    min-width:200px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew10:hover .tooltiptext10 {
    visibility: visible;
    opacity: 1;
}
.slides{
    width:136px;
    height:85px;
    padding:5px;
    display:inline-block;
    border: 1px solid #e3e3e3;
    margin-left: 5px;
}
.dragme{
    cursor: all-scroll;
}
.ptec{
    padding: 5px;
}
.msframe{
    width:125px;
    height:72px;
    overflow: hidden !important;
    border-radius: 10px;
}
.centerside{
    text-align: center;
}
.ileftside{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 9px;
}
.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
}
.swipe-wrap {
    overflow: hidden;
    position: relative;
}
.swipe-wrap > div {
    float: left;
    width: 100%;
    position: relative;
}
.thisfullpage{
    width:100%;
    height: 100%;
    position:relative;
    background-color: #034F4B;
}
.fullpage{
    position:relative;
    width: 100%;
    background-color: #034F4B;
}
.bottom{
    position:absolute;
    bottom:0;
    float:left;
    padding-left:15px;
}
.thismobileexit{
    width: 45px;
    position:absolute;
    bottom:0;
    right: 10px;
    padding-right:5px
}
.popupwb {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
}

.thiswb {
    top:0;
    width: 100vw;
    height: calc(100vh - 45px);
    border: none;
}
.playpbutton{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index:9999;
}
.fade-out {
    opacity: 0;
}
.playpbutton:hover{
    opacity: 0.8;
}