.videorecordepage{
    text-align:center;
    width:480px;
    margin:0 auto
}
.jTvEnS{
    top:10px !important;
    right:10px !important;
    font-size: 16px !important;
}
.kzTnTq{
    background-color: #e82c82 !important;
}
.dloZIt{
    width:32px !important;
    height:32px !important;
    background-color: #e82c82 !important;
}
.Tfijj {
    width:48px !important;
    height:48px !important;
}
.jWtbUI {
    top:75% !important
}
.bwklLI{
    display:none !important
}
.fyBJcg{
    width:50px !important;
    height:50px !important
}
.dEQXOw{
    width:30px !important;
    height:30px !important;
    margin:10px !important;
    background-color: #e82c82 !important;
}
.ikXArp{
    color: #e82c82 !important;
}
.hFwpQm{
    margin-bottom:10px !important;
}
.gayceY{
    background-color: #034f4b !important;
}
.mr-4{
    background-color: #72B5A4;
    margin-left: 20px;
}
.mr-5{
    background-color: #72B5A4;
    margin-left: 20px;
    width:120px;
}
.toprecord{
    margin-top: 10px;
}
.beauvideo{
    border-radius: 5px;
    border: 1px solid #0c4128;
    background-color: black;
}
