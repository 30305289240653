.tutorialpage{
    width:80%;
    margin:40px auto;
    text-align: center;
}
.youtubevideos{
    border-radius: 10px;
    border: 2px solid #034F4B;
    width:960px;
    height: 540px;
}
@media only screen and (max-width: 960px) {
    .tutorialpage{
        width:96%;
    }
    .youtubevideos{
        width:100%;
        height: 520px;
    }
}
