.morereportrooms{
   width:25%;
    height:auto;
    text-align: left !important;
}
.wrapmorereport{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
}

.morereportcheckbox:checked{
    background-color: #007bff;
}
.morereportcheckbox{
    position: absolute;
    opacity: 0; /* Hide the default checkbox */
    cursor: pointer;
}
.morereportrooms{
    position: relative;
    display: inline-block;
    padding-left: 30px; /* Add space for the custom checkbox */
    margin-bottom: 10px;
    cursor: pointer;
}
.morereportrooms:hover .morereportcheckmark {
    background-color: #daf4a2;
}
.morereportcheckmark{
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 2px solid #034F4B;
}

.morereportcheckmark::after{
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 0px;
    width: 10px;
    height: 18px;
    border: solid #595959;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.morereportrooms .morereportcheckbox:checked + .morereportcheckmark::after {
    display: block;
}
.makereportbutton{
    padding: 4px 25px;
    color: #ffffff;
    background-color: #E82C82;
    border:none;
    border-radius: 4px;
}
.purplethis{
    color: #311d48;
    font-size: 22px;
}
.purplethis1{
    color: #a574dc;
    font-size: 18px;
}
.leftsidemake{
    text-align: left !important;
}
