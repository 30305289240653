.uploadimg{
    width:650px;
    margin:60px auto;
}
.imgcenter{
    text-align: center;
}
#emessage{
    color: #E82C82;
    text-align: center;
    margin-top:90px;
}
.card-footer{
    cursor: pointer;
}
.Card.Body{
    padding:3em 0em
}
.uploaded{
    color: #E82C82;
}
.uploadpptx{
    width:650px;
    margin:60px auto;
}
.dropZone{
    padding: 70px 50px;
    width: 580px;
    background:#DAF4A2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}
.redthis{
    color: #e24761;
}
@media only screen and (max-width: 650px) {
    .uploadimg{
        width:100%;
    }
    .dropZone{
        padding:10px;
        width: 100%;
    }
    .uploadpptx{
        width:100%;
    }
}
.redwar{
    color:#0c4128;
}
