.qrshows{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100vh;
    background-image: url("../../img/dash/qrcode-background.jpg");
    background-color: #034F4B;
}
