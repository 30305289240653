#calculatorq, #calculatora{
    width:100%;
    height:350px
}
.mathfaspage{
    width:98%;
    margin:0 auto
}
.calculator {
    height: 350px;
}
.calculatorclass{
    width:100%;
    height:100vh
}
.quesmath{
    width:350px;
    height:40px;
    padding:8px
}
.quesmathinput{
    width:400px;
    height:60px;
    padding:8px
}
.borderthis{
    border: 1px solid #000000;
}
.centerthismath{
    width:450px;
    margin:0 auto
}
.corrctcentermath{
    text-align: center;
    width:98%;
    height:100vh;
    padding-top:30px;
    margin:0 auto;
    background-color: #ffffff;
    padding-bottom: 80px;
    border-radius: 20px;
}
.mathscorrcshow{
    border:1px solid #000000
}
@media only screen and (max-width: 400px){
    .borderthis{
        width:100%
    }
}
@media only screen and (max-width: 450px){
    .centerthismath{
        width:100%
    }
}
@media only screen and (max-width: 600px){
    .mathscorrcshow{
        width:98%
    }
}
