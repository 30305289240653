.orderlistinput{
    width:600px;
}
.orderlist{
    max-width:750px;
    padding:10px;
    background-color: white;
    border-radius: 8px;
    margin:20px auto;
}
.pollpanel{
    background-color:#72B5A4;
    padding:15px;
}
.ques{
    width:100%
}
.fullwidth{
    width:400px;
    height:35px;
}
.listdistanse{
    margin-top: 10px;
}
.thiscenter{
    text-align: center;
    margin-top:10px;
    margin-bottom: 20px;
    color: #034F4B;
}
.timers{
    width:50px
}
.inputorderlist{
    height:39px;
    width:120px;
    background-color:#034F4B;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
}
.orderright{
    text-align: right;
    color:#E82C82;
}
.reorderview{
    text-align: center;
}

.disabledfordrag{
    background-color: #ffffff;
}
.pollheadclock{
    width:75px;
    margin: 10px auto;
}
.grays{
    color: gray;
}
.pollview1{
    width:800px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin: 10px auto;
}
.inputpolllist{
    height:40px;
    width:120px;
    background-color:#034F4B;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
}
.polllist1{
    color: #034F4B;
    display: block;
    position: relative;
    height: 60px;
    text-indent:-35px
}
.percentmiddle{
    padding-top: 15px;
    color:#311d48
}
.pollcontainer{
    list-style: none;
    margin: 0;
    padding-left: 28px;
    overflow: auto;
}
.pollcontainer1{
    list-style: none;
    position: absolute;
    width:200px;
    margin-left: 0;
    padding-bottom: 20px;
}
.pollnoanswers{
    color: #034F4B;
}
.pollmaeeage{
    text-align: center;
    color:#E82C82;
    padding-top: 10px;
}

.polldelete{
    width:30px
}
.polllist{
    color: gray;
    display: block;
    position: relative;
    float: left;
    width: 90%;
    height: 60px;
}
.pollhead{
    text-align: center;
    color: #174963;
}
.polllist input[type=radio]{
    position: absolute;
    visibility: hidden;
}

.polllist label{
    display: block;
    position: relative;
    font-size: 16px;
    padding: 3px 0 0 60px;
    margin: 10px auto;
    height: 18px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
}
#summitb{
    background-color:#DAF4A2;
    color:#034F4B ;
    width:120px;
    border-radius: 5px;
    border: solid #DAF4A2 1px;
    font-weight: 500;
    font-size: 20px;
}
.polllist:hover label{
    color: #000000;
}
#pollsubmit{
    margin-bottom: 80px;
}
.polllist .check{
    display: block;
    position: absolute;
    border: 3px solid gray;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 15px;
    left: 25px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.polllist:hover .check {
    border: 5px solid #000000;
}

.polllist .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top:3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked ~ .check {
    border: 5px solid #311d48;
}

input[type=radio]:checked ~ .check::before{
    background: #311d48;
}

input[type=radio]:checked ~ label{
    color: #034F4B;
}
.pollbottoms{
    margin-bottom: 20px;
}
@media only screen and (max-width: 800px) {
    .pollview1{
        width:100%;
    }
    .orderlist{
    width: 100%;
    }
}
@media only screen and (max-width: 800px) {
    .orderlistinput{
        width:100%
    }
}
.fastanswers{
    padding-top:60px
}
.fastanswes{
    padding-top:8px;
    text-align: left;
}
.greenback{
    background-color:#269B7B;
    padding-top:20px;
    padding-bottom:20px;
    color: white;
    position: relative;
    width: 100%;
    height: auto;
}
.fastview{
    text-align: center;
    width:100%;
    height: 100vh;
}
.fullline{
    width:280px;
    height:40px;
}
.lpollheadclock{
    padding: 30px;
    width:90px;
    margin: 0 auto;
}
.lpollheadclock2{
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 9%;
    transform: translate(-50%, -50%);
}

.enteryournameform{
    margin-top: 15px;
}
.flipcardscreatepage{
    width:90%;
    margin:30px auto
}
.fastanswerspage2{
   margin-bottom: 80px;
    text-align: center;
}
.borderthis{
    border: 1px solid #000000;
}
