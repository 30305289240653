.adobepage{
    width:98%;
    margin:20px auto
}
.createadobebutton{
    width:400px;
    background-color: #d2196e;
}
.adobeshowimages{
    width:960px;
    margin:10px auto
}
.adobedisplay{
    width:960px;
}
.adobedisplaythum{
    width:480px
}
.adobeedittext{
    width:410px;
    margin:10px auto
}
@media only screen and (max-width: 960px){
    .adobeshowimages{
        width:98%
    }
    .adobedisplay{
        width:100%
    }
}
