.timerpage{
    width:422px;
    height: 333px;
    background-color: #ffffff;
    border-radius: 6px;
    margin:85px auto;
    text-align: center;
}
.timerimg{
    width:422px;
    height:333px;
}

.timerg{
    padding-top: 50px;
    margin-left: -30px;
}
