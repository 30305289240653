.vidshows{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100vh;
    background-color: #000000;
}
.centerthisimages{
    position: absolute;
    top: 75%;
    left: 46%;
    transform: translate(-50%, -50%);
    z-index:9999
}
.playervideobutton {
    position: absolute;
    height:40px;
    border: none;
    cursor: pointer;
}
.isvShows{
    position: absolute;
    z-index: 2;
    padding-top: 20px;
    padding-left: 20px;
}
.tooltipvshow {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #333333;
}

.tooltipvshow .toolvshow {
    visibility: hidden;
    width: 180px;
    background-color: #333333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
}

.tooltipvshow:hover .toolvshow {
    visibility: visible;
}
.iurlroom{
    width:100%;
    height:100vh;
    border: none;
}
.atvideobottom{
    position:absolute;
    bottom:0;
    z-index:9999;
    width:98%;
    text-align: center;
}
.fullsynwidth{
    width:90%;
    margin-bottom: 20px;
}
.syncbutton{
    background-color: #fd7e14;
    width:90px;
    margin-bottom: 10px;
}
