.optionanswes{
    text-align: center;
    padding-top: 20px;
}
.optionpage{
   width:800px;
   margin:20px auto
}
.optionslistdistanse{
    list-style-type: none;
    margin-left: -25px;
    margin-top: 10px;
}
.optionsullwidth{
    margin-left: 10px;
    width:500px;
    height:35px
}
.thiseditque{
    width:90%
}
@media only screen and (max-width: 800px) {
    .optionpage{
        width:90%;
        margin:0 auto;
    }
    .orderlist{
        width: 100%;
    }
}
.optionslist{
    margin-top: 10px;
}
.optioncenter{
    text-align: center;
}
.optioncontainer{
    list-style: none;
    margin: 0;
    width:100%;
    padding-left: 20px;
    overflow: auto;
}
.privacytextspan{
    text-decoration: underline;
}
.privacytext{
    font-size: 13px;
    color: #174963;
}
.oneoptionlisttop{
    margin-top:25px;
    padding-left: 10px;
    padding-right: 10px;
}
.oneoptionlist{
    font-size: 1.2rem;
    line-height: 0.8;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}
.biggerradio{
    appearance: none;
    background-color: #fff;
    margin: 0;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
.biggerradio::before{
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #72B5A4;
}
.biggerradio:checked::before {
    transform: scale(1);
}
.drawoptionpage1{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
    margin:0;
    height:100vh
}
.fastanswerspage{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
    overflow: hidden;
    text-align: center;
}
.contright {
    padding: 5px;
    flex: 80%;
}
.contleft{
    padding: 5px;
    flex: 20%;
    background-color:#0c4128;
    height: 100vh;
}
.lpollheadclock1{
    position: absolute;
    width:100px;
    top: 40%;
    left: 10%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.lpollheadclockv{
    position: absolute;
    width:100px;
    top: 40%;
    left: 12%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.lpollheadclockp{
    position: absolute;
    width:100px;
    top: 40%;
    left: 6%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.timer {
    color: #ffffff;
}
@media only screen and (max-width: 760px){
    .drawoptionpage1 {
        display: block;
    }

    .contleft, .contright {
        flex: 100%;
    }
    .contleft{
        background-color: #ffffff;
        height: 100px;
    }
    div.value {
        color: #0c4128;
    }
    .lpollheadclock1{
        position: relative;
        top: 50px;
        left:50%;
    }
    .lpollheadclockv{
        position: relative;
        top: 50px;
        left:50%;
    }
    .lpollheadclockp{
        position: relative;
        top: 50px;
        left:42%;
    }
}
.drawoptionpage2 {
    margin-bottom: 80px;
}
.drawoptioninsidepage{
    width:800px;
    margin:0 auto
}
@media only screen and (max-width: 800px){
    .drawoptioninsidepage{
        width:98%
    }
}
