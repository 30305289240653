.mediaflip-card {
    background-color: transparent;
    width: 960px;
    height: 540px;
    perspective: 1000px;
}

.mediaflip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.mediaflip-card.flipped .mediaflip-card-inner {
    transform: rotateY(180deg);
}
.mediaflip-card-front, .mediaflip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mediaflip-card-front {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #0c4128;
    border-radius: 10px;
}

.mediaflip-card-back {
    background-color: #ffffff;
    color: #000000;
    transform: rotateY(180deg);
    border: 1px solid #0c4128;
    border-radius: 10px;
}
.mediaflipcard{
    width:960px;
    margin:50px auto;
}
.mediainnerflipcard{
    width:98%;
    margin:0 auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    text-align: center;
}
.flashcardright {
    padding: 5px;
    flex: 50%;
}
.flashcardleft{
    padding: 5px;
    flex: 50%;
}
@media screen and (max-width: 960px) {
    .mediaflipcard{
        width:100%
    }
    .mediaflip-card {
        width: 100%;
        height: 540px;
    }
    .mediainnerflipcard img{
        width:100%
    }
}
@media screen and (max-width: 640px) {
    .mediaflip-card {
        width: 100%;
        height: 480px;
    }
    .left, .right {
        flex: 100%;
    }
}
