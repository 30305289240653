.container {
    position: relative;
    display: inline-block;
}

.image-container {
    position: relative;
}

.background-image {
    width: 100%;
    height: auto;
}

.whiteboard {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.highlight-box {
    padding: 10px;
    background-color: yellow;
    border: 2px solid black;
    margin-top: 10px;
}
