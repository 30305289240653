.youtubevideos{
    width:100%;
    height: calc(100vh - 70px);
}
.homepage{
    font-family: 'Brother 1816', sans-serif;
    width:98%;
    margin:20px auto;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
}
.adds{
    margin-bottom: 10px;
}
.meetname{
    cursor: pointer;
}
.justify-content-end{
    cursor: pointer;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    margin:5px auto
}
.copyhere{
color: #2bbece;
}
.addusage{
    text-align: center;
}
img{
    cursor: pointer;
}
.dashlinktable{
    max-width:650px;
}
.accordion-item .accordion-button{
    background-color: #72B5A4;
    color:#ffffff
}
.accordion-button:not(.collapsed){
    background-color: #595959;
    color:#ffffff
}
.namecle{
    font-size: 13px;
    color: #e24761;
}
.accordion-button::after{
    display:none
}
.ibuttons{
    font-family: 'Brother 1816', sans-serif;
    width:145px;
    background-color: #DAF4A2;
    color:#034F4B
}
.dbuttons{
    font-family: 'Brother 1816', sans-serif;
    width:130px;
    background-color: #E82C82;
    float: right;
}
.btn-flat {
    font-family: 'Brother 1816', sans-serif;
    background-color: #034F4B;
    color: white;
}
.tdcenter{
    text-align: center;
}
.tutorialiframe{
    width:100%;
    height:calc(100vh - 60px);
}
.roomnamebig{
    font-size: 22px;
    font-weight: 500;
    color: #0c4128;
    border: none;
    text-align: center;
    width:98%;
}
.updateroomnamebutton{
    float: right;

}
.closefull{
    font-family: 'Brother 1816', sans-serif;
    margin-top: 10px;
    padding-right: 20px;
    height: 50px;
    text-align: right;
    font-size: 25px;
    color: #E24761;
    position: relative;
}
.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #1a1a1a;
    overflow-x: hidden;
    transition: 0.5s;
    text-align: center;
}
.dashboardtooltips{
    text-align: right;
}
.dashboardmiddle{
    text-align: center;
}
.rightside{
    text-align: right;
}
.upgradenew{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}
.tooltiptext {
    font-family: 'Brother 1816', sans-serif;
    min-width:180px;
    top:50%;
    right:100%;
    margin-right:20px;
    transform:translate(0, -50%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}
.upgradenew:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.upgradenew2{
    font-family: 'Brother 1816', sans-serif;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltiptext2 {
    font-family: 'Brother 1816', sans-serif;
    min-width:350px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew2:hover .tooltiptext2 {
    visibility: visible;
    opacity: 1;
}

.upgradenew3{
    font-family: 'Brother 1816', sans-serif;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltiptext3 {
    font-family: 'Brother 1816', sans-serif;
    min-width:180px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew3:hover .tooltiptext3 {
    visibility: visible;
    opacity: 1;
}
.twowordstooltips{
    font-family: 'Brother 1816', sans-serif;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.twowordstooltipstooltip {
    font-family: 'Brother 1816', sans-serif;
    width:130px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.twowordstooltips:hover .twowordstooltipstooltip {
    visibility: visible;
    opacity: 1;
}
@media only screen and (max-width: 850px) {
    .homepage{
        max-width:100%;
    }
}
.popupcard{
    margin-top:120px;
    width:345px;
    position: absolute;
    z-index: 2 !important;
}
.myClassname{
    width:100%;
    margin:0 auto;
}

.thisicon{
    width:40px;
    height:40px
}
.mymodal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
}

/* Modal Content */
.popup-content {
    margin: auto;
    padding: 20px;
    width: 80%;
}

#poppage{
    width:600px;
    top: 50px;
    margin: 0 auto;
}
.searchform{
    width: 250px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    background-color: white;
    background-image: url('../../img/dash/search.png');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 10px 10px 10px 36px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
}
.searchform:focus {
    width: 100%;
}
@media only screen and (max-width: 600px) {
    #poppage{
        width:100%
    }
    .popimgs{
        width:100%
    }
}
.darkgreen{
    color: #034F4B;
    font-weight: bold;
    cursor: pointer;
}
.lightgreen{
    color: #5aab96;
    font-weight: bold;
}
.lightlightgreen{
    color: #5aab96;
}
.buttoncolor{
    font-family: 'Brother 1816', sans-serif;
    color: #000000;
    border-color: #DAF4A2;
    cursor: pointer;
    width:120px;
    height: 40px;
}
.handsign{
    font-family: 'Brother 1816', sans-serif;
    background-color:#72B5A4;
    border-radius: 2px;
    border: 1px solid #72B5A4;
    padding:4px 10px;
    color: #ffffff;
    cursor: pointer;
}
.startbuttoncolor{
    font-family: 'Brother 1816', sans-serif;
    background-color:#034F4B;
    border-radius: 2px;
    border: 1px solid #034F4B;
    padding:4px 10px;
    color: #ffffff;
    cursor: pointer;
    width:150px
}
.invitebuttoncolor{
    font-family: 'Brother 1816', sans-serif;
    background-color: #1d2f2a;
    border-radius: 2px;
    border: 1px solid #72B5A4;
    padding:4px 10px;
    color: #ffffff;
    cursor: pointer;
    width:150px
}
.buttoncolor:hover{
    background-color: #034F4B;
    color: #DAF4A2;
}
.buttoncolor1{
    font-family: 'Brother 1816', sans-serif;
    background-color: #034F4B;
    color: #DAF4A2;
    border-color: #034F4B;
    cursor: pointer;
}
.buttoncolor1:hover{
    background-color: #DAF4A2;
    color: #034F4B;
}
.liteinvite{
    background-color: #034F4B;
    cursor: pointer;
}
.meetinglinksbutton{
    font-family: 'Brother 1816', sans-serif;
    background-color: #269b7b;
    color: white;
    cursor: pointer;
    padding: 4px 10px;
    border:0;
}
.manageleaderbt{
    font-family: 'Brother 1816', sans-serif;
    background-color: #046a65;
    color: white;
    cursor: pointer;
    padding: 4px 10px;
    border:0;
}
.managecontentbtn{
    font-family: 'Brother 1816', sans-serif;
    background-color: #173a35;
    color: white;
    cursor: pointer;
    padding: 4px 10px;
    border:0;
}
.liteinvite:hover{
    background-color: #DAF4A2;
    color: #034F4B;
}
.dashboardroomdelete {
    border-radius: 5px;
}
.lightdarkgreen{
    color: #077a76;
    font-weight: bold;
}
.lightdargreen{
    color: #077a76;
}
.thislinksleft{
    text-align: left;
}
.leftview{
    font-size: 14px;
}
.entername{
    padding:20px 10px
}
.sharemessage{
    width:100%;
    height:35px;
    padding:15px 10px
}
.listdistanse{
    padding:10px
}
.shareemailbutton{
    font-family: 'Brother 1816', sans-serif;
    background-color: #034f4b;
    color: white;
    cursor: pointer;
    padding: 4px 10px;
    border:0;
}
.finishemailbutton{
    font-family: 'Brother 1816', sans-serif;
    background-color: #e82c82;
    color: white;
    cursor: pointer;
    padding: 4px 10px;
    border:0;
}

.shareroompage{
    padding:20px;
    background-color: white;
    border-radius: 6px;
}
button#uncontrolled-tab-example-tab-full.nav-link.active{
    color: #0c4128;
}
button#uncontrolled-tab-example-tab-full.nav-link{
    color: #949494;
}
button#uncontrolled-tab-example-tab-share.nav-link.active{
    color: #0c4128;
}
button#uncontrolled-tab-example-tab-share.nav-link{
    color: #949494;
}
button#uncontrolled-tab-example-tab-resources.nav-link.active{
    color: #0c4128;
}
button#uncontrolled-tab-example-tab-resources.nav-link{
    color: #949494;
}
button#uncontrolled-tab-example-tab-discussion.nav-link.active{
    color: #0c4128;
}
button#uncontrolled-tab-example-tab-discussion.nav-link{
    color: #949494;
}
button#uncontrolled-tab-example-tab-leaderboard.nav-link.active{
    color: #0c4128;
}
button#uncontrolled-tab-example-tab-leaderboard.nav-link{
    color: #949494;
}
button#uncontrolled-tab-example-tab-selfstudy.nav-link.active{
    color: #0c4128;
}
button#uncontrolled-tab-example-tab-selfstudy.nav-link{
    color: #949494;
}
.borderthisexplain{
    border-radius: 10px;
    border: 1px solid #000000;
}
@media only screen and (max-width: 1200px) {
    .borderthisexplain{
       width:100%
    }
}
p{
    font-family: 'Brother 1816', sans-serif;
}
.newstartbuttoncolor{
    font-family: 'Brother 1816', sans-serif;
    padding:6px 20px;
    border-radius: 5px;
    background-color: #E82C82;
    color: #ffffff;
    border:none;
}
.redthisrechum{
    color: #E82C82;
}
.newcalendarbuttoncolor{
    font-family: 'Brother 1816', sans-serif;
    padding:6px 20px;
    border-radius: 5px;
    background-color: #269B7B;
    color: #ffffff;
    border:none;
}
.newunderline{
    font-family: 'Brother 1816', sans-serif;
    color: #bdb8b8;
    text-decoration: underline;
}
.newclasslinksbutton{
    font-family: 'Brother 1816', sans-serif;
    padding:6px 20px;
    border-radius: 5px;
    background-color: #034F4B;
    color: #ffffff;
    border:none;
}
.newinviteradio {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 26px; /* Set your desired width */
    height: 26px; /* Set your desired height */
    border: 2px solid #034F4B;
    border-radius: 50%; /* Makes the button circular */
    cursor: pointer;
    position: relative;
}

.newinviteradio:checked {
    background-color: #ffffff;
}

.newinviteradiolabel{
    font-family: 'Brother 1816', sans-serif;
    cursor: pointer;
    margin-left:5px;
    font-size: 20px;
}
.newinviteradio:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px; /* Set size of the inner dot */
    height: 15px; /* Set size of the inner dot */
    border-radius: 50%; /* Makes the inner dot circular */
    background-color: #034F4B; /* Color of the inner dot */
}

.leftmargin{
    margin:0 5px;
}

.upgradenew20{
    font-family: 'Brother 1816', sans-serif;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltiptext20 {
    font-family: 'Brother 1816', sans-serif;
    min-width:500px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew20:hover .tooltiptext20 {
    visibility: visible;
    opacity: 1;
    text-align: left;
}
.newdashheader{
    width:95%;
    margin: 10px auto -5px;
}
.newleftside{
    text-align: left !important;
}
.newnextchanges{
    font-family: 'Brother 1816', sans-serif;
    padding:5px 15px !important;
    background-color: #595959 !important;
    color: #ffffff;
    border: 1px solid #595959 !important
}
.newcancelchanges{
    font-family: 'Brother 1816', sans-serif;
    padding:5px 15px !important;
    background-color: #595959 !important;
    color: #ffffff;
    border: 1px solid #595959 !important
}
.formcenter{
    text-align: center;
}
.addlessoncenter{
    text-align: center;
}
.roomselectedred{
    color:#E82C82;
    font-size: 22px;
    font-weight: bold;
}
.underlinemedia{
    text-decoration: underline;
    color: #E82C82;
}
.underlinediscussion{
    text-decoration: underline;
    color: #a574dc;
}
.underlineleaderboard{
    text-decoration: underline;
    color: #26967b;
}
.cccentermenus{
    width:70px;
    text-align: center;
    display: inline-block;
}
.fixed-div {
    position: fixed;
    top: 10px;
    left: 0;
    width: 25%;
    margin:0 auto;
    background-color: white;
    z-index: 1000; /* Adjust z-index as necessary */
}
.sametop{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 1px;
}
