.disabledimages{
    opacity: 0.4;
}
.convozemimg{
    padding-top: 10px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0.4;
}
