.searchbarmiddle{
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
}
.searchInput{
    padding: 8px;
    height: 35px;
    font-size: 16px;
    border: 1px solid grey;
    float: left;
    width:70%;
    background: #ffffff;
}
.searchInputbutton{
    float: left;
    padding: 5px;
    color: #174963;
    background: #E6F2F6;
    border: 1px solid #174963;
    width:35px;
    height: 35px;
    border-left: none;
    text-align: center;
    cursor: pointer;
}
.searchInputbutton:hover{
    background: #2BBECE;
}
.searchbarleft{
    padding: 10px;
}
.searchInputbuttons{
    color: #e24761;
    border: 0;
    width: 36px;
    height: 35px;
}
