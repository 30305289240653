.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='sans-serif']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='sans-serif']::before {
    content: 'Sans Serif';
    font-family: sans-serif;
}
.ql-font-times-new-roman {
    font-family: sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Monospace']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Monospace']::before {
    content: 'Monospace';
    font-family: "Monospace"
}
.ql-font-times-new-roman {
    font-family: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='roboto']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='roboto']::before {
    content: 'Roboto';
    font-family: "Roboto"
}

.ql-font-roboto {
    font-family: "Roboto";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Open Sans']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Open Sans']::before {
    content: 'Open Sans';
    font-family: "Open Sans"
}
.ql-font-Open-Sans {
    font-family: "Open Sans";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
    content: 'Montserrat';
    font-family: "Montserrat"
}
.ql-font-Montserrat {
    font-family: "Montserrat";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Lato']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Lato']::before {
    content: 'Lato';
    font-family: "Lato"
}
.ql-font-lato {
    font-family: "Lato";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Play']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Play']::before {
    content: 'Play';
    font-family: "Play"
}
.ql-font-Playpen-Sans {
    font-family: "Play";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Prompt']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Prompt']::before {
    content: 'Prompt';
    font-family: "Prompt"
}
.ql-font-Prompt {
    font-family: "Prompt";
}

.editorcreater{
    min-height:300px
}
.ql-container.ql-snow {
    border: none !important;
}
.sun-editor button.se-btn[data-command="save"] {
    background: #d2196e;
}

